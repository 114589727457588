import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import Big from 'big.js';
import { makeStyles } from '@material-ui/core/styles';
import { isNativeChain } from '../utils/chain';

const makeDefaultStyle = makeStyles({
  root: {
    display: 'inline-flex',
  },
  unit: {
    paddingLeft: '0.3em',
  },
  fiat: {
    paddingLeft: '0.3em',
  },
});

const RlcDisplay = ({
  amount,
  unit = 'RLC',
  displayUnit = true,
  displayFiat = false,
  storeIsNative,
  isNative,
  exRate,
  loading,
  ne = -10,
  pe = 9,
  classes = {},
}) => {
  const isNativeOverride = isNative === undefined ? storeIsNative : isNative;
  const unitName =
    (isNativeOverride ? 'x' : '') + (unit === 'nRLC' ? 'nRLC' : 'RLC');
  const defaultClasses = makeDefaultStyle();

  if (amount === undefined)
    return displayUnit && <Fragment>{unitName}</Fragment>;

  if (loading) {
    return (
      <div className={classes.root || defaultClasses.root}>
        <div className={classes.value}>{' - '}</div>
        {displayUnit && (
          <div className={classes.unit || defaultClasses.unit}>{unitName}</div>
        )}
        {displayFiat && (
          <div className={classes.fiat || defaultClasses.fiat}>{'$ - '}</div>
        )}
      </div>
    );
  }

  Big.NE = ne;
  Big.PE = pe;
  const bigAmount = new Big(amount.toString());
  const unitMultiplier = unit === 'nRLC' ? new Big(1) : new Big(10).pow(-9);
  const value = bigAmount.times(unitMultiplier);
  const displayValue = value.toString();
  const displayFiatValue =
    displayFiat && exRate
      ? value.times(new Big(exRate.toString())).toFixed(2)
      : ' - ';

  return (
    <div className={classes.root || defaultClasses.root}>
      <div className={classes.value}>{displayValue}</div>
      {displayUnit && (
        <div className={classes.unit || defaultClasses.unit}>{unitName}</div>
      )}
      {displayFiat && (
        <div className={classes.fiat || defaultClasses.fiat}>
          ${displayFiatValue}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  storeIsNative: isNativeChain(state.chain.chainId),
});

export default connect(mapStateToProps, null)(RlcDisplay);
