import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  useWalletManager,
  useDeposit,
  useWithdraw,
} from '@iexec/react-wallet-manager';
import BN from 'bn.js';
import { connect } from 'react-redux';
import {
  MuiThemeProvider,
  withStyles,
  createTheme as createMuiTheme,
} from '@material-ui/core/styles';
import * as notifierActions from '../store/actions/notifier';
import { getAssetsPricesAsync } from '../store/actions/prices';
import {
  setShowModal,
  setRunGuide,
  setActiveTab,
} from '../store/actions/accountManager';
import classNames from 'classnames';
import Modal from '@material-ui/core/Modal';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import {
  FaArrowDown,
  FaArrowUp,
  FaCheckCircle,
  FaTimesCircle,
  FaFileSignature,
  FaTimes as Close,
} from 'react-icons/fa';
import Joyride, { EVENTS as joyrideEvents } from 'react-joyride';
import copy from 'copy-to-clipboard';
import { mediaQueries, mediumDisplay } from '../utils/display';
import {
  isNativeChain,
  isSupportedChain,
  isBridged,
  getChainKey,
  getChainName,
  getBridgedChainName,
  chainIds,
} from '../utils/chain';
import { rlcToNano, nanoToRlc } from '../utils/maths';
import { toShortHash } from '../utils/format';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import RlcDisplay from './RlcDisplay';
import AssetDisplay from './AssetDisplay';
import waves from '../assets/images/waves.svg';
import walletIcon from '../assets/images/wallet.svg';
import accountIcon from '../assets/images/account.svg';
import rlcAssetIcon from '../assets/images/asset-rlc.svg';
import ethAssetIcon from '../assets/images/asset-eth.svg';
import disconnectIcon from '../assets/images/icon-logoutX.svg';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepContent from '@material-ui/core/StepContent';
import StepLabel from '@material-ui/core/StepLabel';
import { useEffect } from 'react';
import { DEFAULT_CHAIN } from '../config';

const isGt = (value, ref) => new BN(value).gt(new BN(ref));
const isZero = (value) => new BN(value).isZero();

const theme = createMuiTheme({
  typography: {
    fontFamily: [
      'Overpass-Regular, sans-serif',
      'Montserrat-Regular, sans-serif',
    ].join(','),
    subtitle1: {
      fontFamily: 'Montserrat-Regular, sans-serif',
    },
    fontSize: 16,
    color: '#304058',
  },
  palette: {
    background: {
      default: '#FFFFFF',
      paper: '#F5F5F5',
      wallet: '#304058',
      account: '#F9C300',
      walletStart: '#304058',
      accountStart: '#F9C300',
      walletEnd: '#304058',
      accountEnd: '#F9C300',
    },
    text: {
      primary: '#304058',
      secondary: '#304058',
    },
    primary: {
      light: '#FAE900',
      main: '#F9C300',
      dark: '#EABA0A',
      contrastText: '#5D4B00',
    },
  },
  overrides: {
    MuiMenuItem: {
      root: {
        fontWeight: 'bold',
        fontSize: '1.2em',
      },
    },
    MuiSelect: {
      root: {
        fontWeight: 'bold',
        fontSize: '1.2em',
      },
      select: {
        '&:focus': {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiFormControlLabel: {
      label: {
        fontWeight: 'bold',
      },
    },
    MuiTabs: {
      root: {
        backgroundColor: '#FFFFFF',
      },
      indicator: {
        display: 'none',
      },
    },
    MuiTab: {
      root: {
        lineHeight: '1.25',
        minWidth: '0 ! important',
        margin: '1px',
        backgroundColor: '#ECEAEA',
        textTransform: 'none',
        fontWeight: 'bold',
        '&$selected': {
          backgroundColor: '#F9C300',
          color: '#5D4B00',
        },
        [mediaQueries.medium.down]: {
          fontSize: '0.9rem',
        },
      },
    },
    MuiInput: {
      root: {
        '& input': {
          fontSize: '1.2em',
          padding: '6px 8px',
          border: '1px solid #304058',
          borderRadius: '4px',
          backgroundColor: '#FFFFFF',
        },
      },
    },
    MuiButton: {
      root: {
        fontWeight: 'bold',
        textTransform: 'none',
      },
      label: {
        paddingLeft: '2em',
        paddingRight: '2em',
      },
    },
    MuiStepper: {
      root: {
        background: 'none',
        padding: 0,
        margin: '2em 0 1em 0',
      },
    },
  },
});

const styles = (theme) => ({
  paper: {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
    padding: '2em',
    overflowY: 'auto',
    fontSize: '1.2em',
    width: '100%',
    height: '100%',
    [mediaQueries.medium.up]: {
      width: 'auto',
      minWidth: '60vw',
      maxWidth: '90vw',
      height: 'auto',
      minHeight: '78vh',
      maxHeight: '90vh',
    },
  },
  centralColumn: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    maxWidth: '1200px',
    height: '100%',
  },
  disconnectButton: {
    '& img': {
      height: '1.8em',
    },
    width: 'min-content',
    cursor: 'pointer',
    display: 'block',
    marginRight: 'auto',
    padding: '0.2em 0.5em',
    marginTop: '-1em',
  },
  closeButton: {
    cursor: 'pointer',
    display: 'block',
    marginLeft: 'auto',
    padding: '0.2em',
    width: '2.4em',
    marginBottom: '-2.6em',
    marginTop: '-1em',
    [mediaQueries.medium.up]: {
      display: 'none',
    },
  },
  header: {
    margin: '1em',
    textAlign: 'center',
  },
  address: {
    marginTop: '0.3em',
    padding: '0.2em',
    cursor: 'copy',
  },
  chainSelector: {
    padding: '0.2em',
    width: 'max-content',
    margin: 'auto',
  },
  badge: {
    display: 'inline-block',
    margin: 'auto 0.5em',
    height: '0.7em',
    width: '0.7em',
    borderRadius: '0.35em',
    backgroundColor: 'orange',
    verticalAlign: 'middle',
  },
  green: {
    backgroundColor: '#11B15E',
  },
  red: {
    backgroundColor: 'red',
  },
  needHelp: {
    marginTop: '1em',
    '& span': {
      cursor: 'pointer',
      color: 'blue',
    },
  },
  walletContainer: {
    marginTop: '2em',
    display: 'block',
    [mediaQueries.medium.up]: {
      display: 'flex',
      flexDirection: 'row',
    },
  },
  transfertArrow: {
    flex: 1,
    margin: 'auto',
    padding: '10px',
    textAlign: 'center',
    [mediaQueries.medium.up]: {
      '&> svg': {
        transform: 'rotate(-90deg)',
      },
    },
  },
  spacer: {
    flex: 1,
  },
  simpleSpacer: {
    margin: '1.5em',
  },
  card: {
    flex: 2,
    margin: 'auto',
    borderRadius: '10px',
    boxShadow: '0 2px 4px 0 #4F5C73',
    display: 'flex',
    flexDirection: 'row',
    [mediaQueries.medium.up]: {
      minWidth: '180px',
      maxWidth: '250px',
      flexDirection: 'column',
    },
  },
  walletIconContainer: {
    display: 'none',
    textAlign: 'center',
    lineHeight: '100%',
    [mediaQueries.mobile.up]: {
      display: 'inline-flex',
      borderRadius: '10px 0 0 10px',
    },
    [mediaQueries.medium.up]: {
      display: 'block',
      borderRadius: '10px 10px 0 0',
    },
  },
  walletBackground: {
    background: `linear-gradient(0.1turn, ${theme.palette.background.walletStart}, ${theme.palette.background.walletEnd})`,
  },
  accountBackground: {
    background: `linear-gradient(0.1turn, ${theme.palette.background.accountStart}, ${theme.palette.background.accountEnd})`,
  },
  walletIcon: {
    width: '100px',
    height: '100px',
    padding: '10px',
    margin: 'auto',
  },
  walletText: {
    flex: 1,
    padding: '20px',
    minWidth: '150px',

    '& h3': {
      textAlign: 'center',
      marginBottom: '1em',
      fontSize: '1em',
      fontWeight: 'bold',
    },
    [mediaQueries.medium.up]: {
      minHeight: '150px',
      '& h3': {
        fontSize: '0.8em',
      },
    },
  },
  balanceContainer: {
    paddingTop: '0.5em',
    fontSize: '0.8em',
    display: 'flex',
    '&> :first-child': {
      width: 0,
      marginRight: '25%',
    },
    '&> :last-child': {
      width: '75%',
    },
    '& img': {
      height: '2.2em',
      width: '2.2em',
      marginTop: '0.1em',
      marginRight: '0.5em',
    },
    [mediaQueries.medium.down]: {
      fontSize: '1.2em',
      '& img': {
        height: '2.5em',
        width: '2.5em',
      },
    },
  },
  connectDefaultChainContainer: {
    marginTop: '3rem',
    display: 'flex',
    '&>*': {
      margin: 'auto',
    },
  },

  actionsContainer: {
    margin: '3em 0',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: '1em',
  },
  action: {
    minHeight: '250px',
    padding: '2em',
    backgroundColor: '#FFFFFF',
    background: `url(${waves})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: 'flex',
    flexDirection: 'column',
    '& h3': {
      marginBottom: '0.5em',
      fontSize: '1em',
      fontWeight: 'bold',
    },
  },
  inlineForm: {
    marginTop: '1em',
    display: 'flex',
    flexDirection: 'row',
    '& input': {
      height: '22px',
    },
    '& button': {
      height: '36px',
    },
    '& p': {
      textAlign: 'right',
    },
  },
  inputGroup: {
    marginRight: '1em',
  },
  inputAdornment: {
    fontSize: '0.8em',
    fontWeight: 'bold',
    marginLeft: '-4.5em',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
  stepText: {
    display: 'flex',
    paddingLeft: '2em',
    margin: '1em 0',
    '& svg': {
      margin: 'auto 1em',
    },
    '& span': {
      margin: 'auto 0',
    },
    '& p': {
      margin: 'auto 0',
    },
    '& a': {
      color: '#304058',
    },
  },
  resetSteps: {
    margin: '1em 0',
  },
  getAssetsContent: {
    '& button': {
      margin: '1em 0',
    },
  },
  moonpayWidget: {
    maxWidth: '450px',
    height: '450px',
    margin: '1em -15px',
    backgroundColor: 'white',
  },
  guideTooltip: {
    textAlign: 'left',
    '& div': {
      marginBottom: '0.2em',
    },
    '& ul': {
      marginLeft: '1.5em',
    },
  },
});

const assetDisplayStyles = {
  root: {
    fontWeight: 'bold',
    display: 'flex',
    flexWrap: 'wrap',
  },
  value: {
    flex: 1,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    textAlign: 'right',
    paddingRight: '0.3em',
  },
  unit: {},
  fiat: {
    width: '100%',
    textAlign: 'right',
    fontWeight: 'normal',
  },
};

const StyledRlcDisplay = withStyles(assetDisplayStyles)(RlcDisplay);
const StyledAssetDisplay = withStyles(assetDisplayStyles)(AssetDisplay);

const ChainSelector = ({ classes, className, changeChain, chainId }) => {
  const inputValues = chainIds.map((id) => (
    <MenuItem value={id} key={id}>
      <span
        className={classNames(
          classes.badge,
          id === 1 || id === 134 ? classes.green : null,
        )}
      />
      {getChainName(id)}
    </MenuItem>
  ));
  if (!isSupportedChain(chainId))
    inputValues.push(
      <MenuItem value={chainId} key={chainId}>
        <span className={classNames(classes.badge, classes.red)} />
        {`Unsupported Network ${chainId}`}
      </MenuItem>,
    );
  return (
    <div className={classNames(classes.chainSelector, className)}>
      <FormControl>
        <Select
          disableUnderline
          value={chainId}
          onChange={(e) => {
            if (e.target.value !== chainId) changeChain(e.target.value);
          }}
        >
          {inputValues}
        </Select>
      </FormControl>
    </div>
  );
};

const getTourSteps = ({ classes, provider, isNative, chainId }) => {
  let steps = [];
  steps.push({
    target: '.overviewTarget',
    title: 'Welcome to your Wallet Manager',
    content: (
      <div className={classes.guideTooltip}>
        <div>Let us give you a short introduction</div>
      </div>
    ),
    placement: 'center',
  });
  steps.push({
    target: '.addressTarget',
    title: 'Here is your wallet address',
    content: (
      <div className={classes.guideTooltip}>
        <div>
          This wallet address is your public identity on the blockchain.
        </div>
        <div>
          This is your <b>{provider}</b> wallet address.
        </div>
      </div>
    ),
  });
  steps.push({
    target: '.walletTarget',
    title: 'Here is your Wallet',
    content: (
      <div className={classes.guideTooltip}>
        <div>Your crypto-currencies can be found here.</div>
        {!isNative && (
          <div>
            When connected to {getChainName(chainId)}, all transactions require
            a small amount of ETH for transaction fees. Ensure you have enough
            ETH in your wallet to pay these.
          </div>
        )}
      </div>
    ),
  });
  steps.push({
    target: '.accountTarget',
    title: 'Here is your iExec Account',
    content: (
      <div className={classes.guideTooltip}>
        <div>
          Your account is linked to your wallet address. The account shows your
          credit ready to spend on the iExec platform.
        </div>
        <div>
          <ul>
            <li>
              When buying resources, you spend <RlcDisplay /> from your iExec
              Account
            </li>
            <li>
              When selling resources, the <RlcDisplay /> earned can be found in
              your iExec Account
            </li>
          </ul>
        </div>
      </div>
    ),
  });
  steps.push({
    target: '.depositTarget',
    content: (
      <div className={classes.guideTooltip}>
        <div>
          Use{' '}
          <b>
            Deposit <RlcDisplay />
          </b>{' '}
          to top up your iExec Account with funds from your wallet.
        </div>
      </div>
    ),
    activeTab: 'deposit',
  });
  steps.push({
    target: '.withdrawTarget',
    content: (
      <div className={classes.guideTooltip}>
        <div>
          Use{' '}
          <b>
            Withdraw <RlcDisplay />
          </b>{' '}
          to withdraw the <RlcDisplay /> from your iExec Account to your wallet.
        </div>
      </div>
    ),
    activeTab: 'withdraw',
  });
  if (getChainKey(chainId) && isBridged(getChainKey(chainId)))
    steps.push({
      target: '.bridgeTarget',
      title: (
        <>
          You can transfer your <RlcDisplay isNative={!isNative} /> between{' '}
          {getBridgedChainName(chainId)} and {getChainName(chainId)}
        </>
      ),
      content: (
        <div className={classes.guideTooltip}>
          <div>
            Use <b>Send to {getBridgedChainName(chainId)}</b> to transfer some{' '}
            <RlcDisplay /> from your wallet to {getBridgedChainName(chainId)}.
          </div>
          <div>
            Remember:
            <ul>
              <li>
                1 <RlcDisplay isNative={!isNative} /> on{' '}
                {getBridgedChainName(chainId)} has the same value as 1{' '}
                <RlcDisplay isNative={isNative} /> on {getChainName(chainId)}.
              </li>
              <li>
                You can then transfer <RlcDisplay isNative={!isNative} /> from{' '}
                {getBridgedChainName(chainId)} back to {getChainName(chainId)}.
              </li>
            </ul>
          </div>
        </div>
      ),
      activeTab: 'bridge',
    });
  steps.push({
    target: '.disconnectTarget',
    content: (
      <div className={classes.guideTooltip}>
        <div>Click this button to disconnect your current wallet.</div>
        <div>You can then connect another wallet.</div>
      </div>
    ),
    placement: 'auto',
  });
  return steps;
};

const DepositWidget = (props) => {
  const { classes } = props;

  const [hideError, setHideError] = useState(false);
  const [amount, setAmount] = useState('');
  const [activeStep, setActiveStep] = useState(0);

  const { deposit, pending, error, txHash } = useDeposit();
  const { provider, tokenBalance, iexec } = useWalletManager();

  useEffect(() => {
    // cleanup only
    return () => {
      setHideError(false);
      setActiveStep(0);
    };
  }, [iexec]);

  useEffect(() => {
    if (pending) {
      setActiveStep(1);
    }
  }, [pending]);

  useEffect(() => {
    if (txHash) {
      setActiveStep(2);
    }
  }, [txHash]);

  const retry = () => {
    setHideError(true);
    setAmount('');
    setActiveStep(0);
  };

  const steps = [
    'Choose the amount to deposit',
    'Sign deposit tx',
    <>
      <RlcDisplay /> deposited
    </>,
  ];

  const formError = isGt(rlcToNano(amount), tokenBalance)
    ? 'insufficient wallet balance'
    : '';
  const isInputValid = !formError && amount && !isZero(rlcToNano(amount));

  const stepContent = (
    <>
      {activeStep === 0 && (
        <FormControl className={classes.inlineForm}>
          <div className={classes.inputGroup}>
            <Input
              value={amount}
              onChange={(event) => setAmount(event.target.value)}
              disableUnderline
              endAdornment={
                <InputAdornment position="start">
                  <IconButton
                    className={classes.inputAdornment}
                    onClick={() => {
                      setAmount(nanoToRlc(tokenBalance));
                    }}
                  >
                    MAX
                  </IconButton>
                </InputAdornment>
              }
            />
            <FormHelperText error={!!formError}>{formError}</FormHelperText>
          </div>
          <Button
            variant="contained"
            disableElevation
            color="primary"
            disabled={!isInputValid}
            onClick={() => deposit(rlcToNano(amount))}
          >
            Deposit
          </Button>
        </FormControl>
      )}
      {activeStep === 1 && (
        <div className={classes.stepText}>
          <FaFileSignature size="1.5em" />
          <span>Use {provider} to sign deposit transaction</span>
        </div>
      )}
    </>
  );

  return (
    <>
      <h3>
        Deposit <RlcDisplay /> to your Account
      </h3>
      <div>
        Top up your iExec account with your wallet to use iExec platform
      </div>
      <Stepper
        activeStep={activeStep}
        orientation={mediumDisplay ? 'vertical' : 'horizontal'}
      >
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          if (!!error && !hideError && activeStep === index)
            labelProps.error = true;
          if (steps.length === index + 1 && activeStep === index)
            labelProps.completed = true;
          return (
            <Step key={index} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
              {mediumDisplay && <StepContent>{stepContent}</StepContent>}
            </Step>
          );
        })}
      </Stepper>

      {!mediumDisplay && stepContent}

      {activeStep + 1 === steps.length && (
        <div className={classes.stepText}>
          <FaCheckCircle color="green" size="1.5em" />
          <span>Deposit successful</span>
        </div>
      )}
      {!!error && !hideError && (
        <div className={classes.stepText}>
          <FaTimesCircle color="red" size="1.5em" />
          <span>{error}</span>
        </div>
      )}
      {((!!error && !hideError) || activeStep + 1 === steps.length) && (
        <div className={classes.resetSteps}>
          <Button
            variant="contained"
            disableElevation
            color="primary"
            onClick={retry}
          >
            New deposit
          </Button>
        </div>
      )}
    </>
  );
};

const WithdrawWidget = (props) => {
  const { classes } = props;

  const [hideError, setHideError] = useState(false);
  const [amount, setAmount] = useState('');
  const [activeStep, setActiveStep] = useState(0);

  const { withdraw, pending, error, txHash } = useWithdraw();
  const { provider, stakeBalance, iexec } = useWalletManager();

  useEffect(() => {
    // cleanup only
    return () => {
      setHideError(false);
      setActiveStep(0);
    };
  }, [iexec]);

  useEffect(() => {
    if (pending) {
      setActiveStep(1);
    }
  }, [pending]);

  useEffect(() => {
    if (txHash) {
      setActiveStep(2);
    }
  }, [txHash]);

  const retry = () => {
    setHideError(true);
    setAmount('');
    setActiveStep(0);
  };

  const steps = [
    'Choose the amount to withdraw',
    'Sign withdraw tx',
    <>
      <RlcDisplay /> withdrawn
    </>,
  ];

  const formError = isGt(rlcToNano(amount), stakeBalance)
    ? 'insufficient account balance'
    : '';
  const isInputValid = !formError && amount && !isZero(rlcToNano(amount));

  const stepContent = (
    <>
      {activeStep === 0 && (
        <FormControl className={classes.inlineForm}>
          <div className={classes.inputGroup}>
            <Input
              value={amount}
              onChange={(event) => setAmount(event.target.value)}
              disableUnderline
              endAdornment={
                <InputAdornment position="start">
                  <IconButton
                    className={classes.inputAdornment}
                    onClick={() => {
                      setAmount(nanoToRlc(stakeBalance));
                    }}
                  >
                    MAX
                  </IconButton>
                </InputAdornment>
              }
            />
            <FormHelperText error={!!formError}>{formError}</FormHelperText>
          </div>
          <Button
            variant="contained"
            disableElevation
            color="primary"
            disabled={!isInputValid}
            onClick={() => withdraw(rlcToNano(amount))}
          >
            Withdraw
          </Button>
        </FormControl>
      )}
      {activeStep === 1 && (
        <div className={classes.stepText}>
          <FaFileSignature size="1.5em" />
          <span>Use {provider} to sign withdraw transaction</span>
        </div>
      )}
    </>
  );

  return (
    <>
      <h3>
        Withdraw <RlcDisplay /> to your Wallet
      </h3>
      <div>Withdraw your profits from your iExec Account</div>
      <Stepper
        activeStep={activeStep}
        orientation={mediumDisplay ? 'vertical' : 'horizontal'}
      >
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          if (!!error && !hideError && activeStep === index)
            labelProps.error = true;
          if (steps.length === index + 1 && activeStep === index)
            labelProps.completed = true;
          return (
            <Step key={index} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
              {mediumDisplay && <StepContent>{stepContent}</StepContent>}
            </Step>
          );
        })}
      </Stepper>

      {!mediumDisplay && stepContent}

      {activeStep + 1 === steps.length && (
        <div className={classes.stepText}>
          <FaCheckCircle color="green" size="1.5em" />
          <span>Withdrawal successful</span>
        </div>
      )}
      {!!error && !hideError && (
        <div className={classes.stepText}>
          <FaTimesCircle color="red" size="1.5em" />
          <span>{error}</span>
        </div>
      )}
      {((!!error && !hideError) || activeStep + 1 === steps.length) && (
        <div className={classes.resetSteps}>
          <Button
            variant="contained"
            disableElevation
            color="primary"
            onClick={retry}
          >
            New withdrawal
          </Button>
        </div>
      )}
    </>
  );
};

const AccountManagerModal = (props) => {
  const {
    classes,
    showModal,
    runGuide,
    setRunGuide,
    activeTab,
    setActiveTab,
    ethExRate,
    rlcExRate,
    closeModal,
    getAssetsPrices,
    notify,
  } = props;

  const {
    provider,
    connected,
    chainId,
    address,
    balance,
    tokenBalance,
    stakeBalance,
    changeNetwork,
    disconnectProvider,
  } = useWalletManager();

  // start fetching assets prices
  useEffect(() => {
    getAssetsPrices();
  }, [getAssetsPrices]);

  useEffect(() => {
    if (!connected) {
      closeModal();
    }
  }, [closeModal, connected]);

  // close modal on back
  const { listen: historyListen, push: historyPush } = useHistory();
  useEffect(() => {
    const unlisten = historyListen((location, action) => {
      if (action === 'POP' && showModal) {
        closeModal();
      }
    });
    return unlisten;
  }, [closeModal, historyListen, showModal]);
  useEffect(() => {
    if (showModal) {
      historyPush('#');
    }
  }, [historyPush, showModal]);

  const isNative = isNativeChain(chainId);

  const handleJoyrideCallback = (data) => {
    const { step, type } = data;
    if (step.activeTab && type === joyrideEvents.STEP_BEFORE) {
      setActiveTab(step.activeTab);
    }
    if (type === joyrideEvents.TOUR_END || type === joyrideEvents.BEACON) {
      setRunGuide(false);
    }
  };

  const getActiveTab = () => {
    if (
      isNative &&
      (activeTab === 'eth' ||
        activeTab === 'rlc' ||
        activeTab === 'bridgeToSidechain')
    ) {
      return 'deposit';
    }
    if (!isNative && activeTab === 'bridgeToMainchain') {
      return 'deposit';
    }
    if (
      isNative &&
      activeTab === 'bridgeToMainchain' &&
      // check not bridged
      !(getChainKey(chainId) && isBridged(getChainKey(chainId)))
    ) {
      return 'deposit';
    }
    return activeTab;
  };

  return (
    <Modal open={showModal} onClose={closeModal}>
      <Paper className={classNames(classes.paper, 'overviewTarget')}>
        <div
          onClick={closeModal}
          aria-label={'Close'}
          className={classNames(classes.closeButton, 'hint--left')}
        >
          <Close size="2em" color={'#a2b5d2'} />
        </div>
        <div
          onClick={() => disconnectProvider().catch(() => {})}
          aria-label={'Logout'}
          className={classNames(classes.disconnectButton, 'disconnectTarget')}
        >
          <img src={disconnectIcon} alt="Logout" />
        </div>
        <div className={classes.centralColumn}>
          <Joyride
            steps={getTourSteps({
              classes,
              provider,
              isNative,
              chainId,
            })}
            callback={handleJoyrideCallback}
            continuous={true}
            showSkipButton={true}
            run={runGuide}
            spotlightPadding={0}
            styles={{
              options: {
                primaryColor: '#F9C300',
                textColor: '#304058',
                zIndex: 10000,
              },
              buttonClose: {
                display: 'none',
              },
            }}
          />
          <div className={classes.spacer} />
          <div className={classes.header}>
            <h2>iExec Wallet Manager</h2>
            <div
              aria-label="click to copy"
              onClick={(e) => {
                copy(address);
                notify({ message: 'copied to clipboard' });
              }}
              className={classNames(
                classes.address,
                'hint--right',
                'addressTarget',
              )}
            >
              {address && toShortHash(address)}
            </div>
            <ChainSelector
              className={'chainSelectorTarget'}
              classes={classes}
              chainId={chainId}
              changeChain={(newChainId) =>
                changeNetwork(newChainId).catch((e) =>
                  notify({ level: 'error', message: e.message }),
                )
              }
            />
            <div className={classes.needHelp}>
              <span onClick={() => setRunGuide(true)}>Need help?</span>
            </div>
          </div>
          {isSupportedChain(chainId) && (
            <>
              <div className={classes.spacer} />
              <div className={classes.walletContainer}>
                <div className={classes.spacer} />
                <div className={classNames(classes.card, 'walletTarget')}>
                  <div
                    className={classNames(
                      classes.walletIconContainer,
                      classes.walletBackground,
                    )}
                  >
                    <img
                      src={walletIcon}
                      alt="your wallet"
                      className={classes.walletIcon}
                    />
                  </div>
                  <div className={classes.walletText}>
                    <h3>Your Wallet</h3>
                    {!isNative && (
                      <div className={classes.balanceContainer}>
                        <div>
                          <img src={ethAssetIcon} alt="eth" />
                        </div>
                        <StyledAssetDisplay
                          amount={balance}
                          exRate={ethExRate}
                          displayFiat={true}
                        />
                      </div>
                    )}
                    <div className={classes.balanceContainer}>
                      <div>
                        <img src={rlcAssetIcon} alt="rlc" />
                      </div>
                      <StyledRlcDisplay
                        amount={tokenBalance}
                        displayFiat={true}
                        exRate={rlcExRate}
                        isNative={isNative}
                      />
                    </div>
                  </div>
                </div>
                <div className={classes.transfertArrow}>
                  {activeTab === 'deposit' && <FaArrowDown size="2em" />}
                  {activeTab === 'withdraw' && <FaArrowUp size="2em" />}
                </div>
                <div className={classNames(classes.card, 'accountTarget')}>
                  <div
                    className={classNames(
                      classes.walletIconContainer,
                      classes.accountBackground,
                    )}
                  >
                    <img
                      src={accountIcon}
                      alt="your account"
                      className={classes.walletIcon}
                    />
                  </div>
                  <div className={classes.walletText}>
                    <h3>Your iExec Account</h3>
                    <div className={classes.balanceContainer}>
                      <div>
                        <img src={rlcAssetIcon} alt="rlc" />
                      </div>
                      <StyledRlcDisplay
                        amount={stakeBalance}
                        displayFiat={true}
                        exRate={rlcExRate}
                        isNative={isNative}
                      />
                    </div>
                  </div>
                </div>
                <div className={classes.spacer} />
              </div>

              <div className={classes.actionsContainer}>
                <Tabs
                  value={getActiveTab()}
                  onChange={(event, value) => setActiveTab(value)}
                  variant="fullWidth"
                >
                  <Tab
                    label={
                      <>
                        Deposit <RlcDisplay />
                      </>
                    }
                    value={'deposit'}
                    className={'depositTarget'}
                  />
                  <Tab
                    label={
                      <>
                        Withdraw <RlcDisplay />
                      </>
                    }
                    value={'withdraw'}
                    className={'withdrawTarget'}
                  />
                  {getChainKey(chainId) && isBridged(getChainKey(chainId)) && (
                    <Tab
                      label={
                        <>
                          Bridge <RlcDisplay isNative={isNative} />/
                          <RlcDisplay isNative={!isNative} />
                        </>
                      }
                      value={'bridge'}
                      className={'bridgeTarget'}
                    />
                  )}
                </Tabs>
                <div className={classNames(classes.action)}>
                  {activeTab === 'deposit' && (
                    <DepositWidget classes={classes} />
                  )}
                  {activeTab === 'withdraw' && (
                    <WithdrawWidget classes={classes} />
                  )}
                  {activeTab === 'bridge' && (
                    <>
                      <h3>
                        Bridge your <RlcDisplay isNative={isNative} />/
                        <RlcDisplay isNative={!isNative} /> between chains
                      </h3>
                      <div>
                        Move your <RlcDisplay isNative={isNative} />/
                        <RlcDisplay isNative={!isNative} /> in your wallet
                        between {getChainName(chainId)} and{' '}
                        {getBridgedChainName(chainId)} with our bridge.
                      </div>
                      <div className={classes.inlineForm}>
                        <a
                          href="https://bridge-bellecour.iex.ec"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <Button
                            variant="contained"
                            disableElevation
                            color="primary"
                          >
                            Visit the bridge
                          </Button>
                        </a>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </>
          )}
          {!isSupportedChain(chainId) && (
            <div className={classes.connectDefaultChainContainer}>
              <Button
                variant="contained"
                disableElevation
                color="primary"
                onClick={() => {
                  changeNetwork(DEFAULT_CHAIN).catch((e) =>
                    notify({ level: 'error', message: e.message }),
                  );
                }}
              >
                Connect to {getChainName(DEFAULT_CHAIN)}
              </Button>
            </div>
          )}
          <div className={classes.spacer} />
        </div>
      </Paper>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  showModal: state.accountManager.showModal,
  runGuide: state.accountManager.runGuide,
  activeTab: state.accountManager.activeTab,
  ethExRate: state.prices.ethPrices.USD,
  rlcExRate: state.prices.rlcPrices.USD,
});

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(setShowModal(false)),
  setRunGuide: (bool) => dispatch(setRunGuide(bool)),
  setActiveTab: (value) => dispatch(setActiveTab(value)),
  getAssetsPrices: () => dispatch(getAssetsPricesAsync()),
  notify: (params) => dispatch(notifierActions.notify(params)),
});

const AccountManager = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(AccountManagerModal));

const StyledAccountManager = (props) => {
  return (
    <MuiThemeProvider theme={theme}>
      <AccountManager />
    </MuiThemeProvider>
  );
};

export default StyledAccountManager;
